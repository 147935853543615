<template>
  <div class="essay-submission-container-pc" v-if="!isMobile">
    <div style="padding-top: 0.1px">
      <!-- ======= Header ======= -->
      <header id="header" class="d-flex align-items-center">
        <div class="container d-flex align-items-center justify-content-between">
          <h1 class="logo">
            <a><img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/2024/03/25/575f2b4917254290be12e5a5be3c5be1/logo.png" alt="" /></a>
          </h1>
          <nav id="navbar" class="navbar">
            <ul>
              <li><a class="nav-link scrollto" style="cursor: pointer" @click="$router.push({name: 'home'})">{{ isCN ? "首页" : "Home" }}</a></li>
              <!--              <li class="dropdown">
                              <a>
                                {{ isCN ? "日程" : "Program" }}
                                <i class="bi bi-chevron-down"/>
                              </a>
                              <ul class="huigu">
                                <template v-if="isCN">
                                  <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864" target="_blank">会议日程</a></li>
                                  <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864/search?agendaId=1394574515748864" target="_blank">讲者检索</a></li>
                                </template>
                                <template v-else>
                                  <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864?en=1" target="_blank">Forums</a></li>
                                  <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864/search?agendaId=1394574515748864&en=1" target="_blank">Speakers</a></li>
                                </template>
                              </ul>
                            </li>-->
              <li>
                <a
                    class="nav-link scrollto active"
                    @click="$router.push({name: 'essaySubmission'})"
                    style="cursor: pointer"
                >{{ isCN ? "征文投稿" : "Call for abstracts" }}</a>
              </li>
              <li>
                <a
                    class="nav-link scrollto"
                    @click="$router.push({name: 'caseSubmission'})"
                    style="cursor: pointer"
                >{{ isCN ? "病例投稿" : "Call for cases" }}</a>
              </li>
              <li>
                <a
                    class="nav-link scrollto"
                    href="https://www.drvoice.cn/v2/course/3328"
                    target="_blank"
                >{{ isCN ? "网络直播" : "Webcast" }}</a>
              </li>
              <li>
                <a
                    class="nav-link scrollto"
                    :href="isCN?'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320':'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320?language=en'"
                    target="_blank"
                >Webinars</a>
              </li>
              <li v-if="isCN">
                <a
                    class="nav-link scrollto"
                    href="https://www.pailixiang.com/m/album/main_ig65930788.html"
                    target="_blank"
                >照片直播</a>
              </li>
              <li>
                <a
                  class="nav-link scrollto"
                  :href="isCN?'https://www.drvoice.cn/v2/conf/1504/column/1423367011369472':'https://www.drvoice.cn/v2/conf/1504/column/1423366932603392'"
                  target="_blank"
                >{{ isCN?'资料下载':'Download' }}</a>
              </li>
              <!--              <li v-if="isCN">
                              <a
                                class="nav-link scrollto"
                                href="https://www.drvoice.cn/cvh2024/workshop"
                              >Workshop预约</a>
                            </li>
                            <li v-if="!isCN">
                              <a
                                class="nav-link scrollto"
                                href="https://www.drvoice.cn/v2/conf/1120/column/1400736462077952?language=en"
                              >Download</a>
                            </li>
                            <li class="dropdown" v-if="isCN">
                              <a>
                                会议资料
                                <i class="bi bi-chevron-down"/>
                              </a>
                              <ul class="huigu">
                                <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1403064991039744">参会指南</a></li>
                                <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1404045771178240">会议资讯</a></li>
                                <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1400736361062400">资料下载</a></li>
                              </ul>
                            </li>
                            <li v-if="!isCN">
                              <a
                                class="nav-link scrollto"
                                href="https://www.drvoice.cn/v2/conf/1120/column/1404045771178240"
                              >News</a>
                            </li>
                            <li >
                              <a
                                class="nav-link scrollto"
                                href="https://www.drvoice.cn/v2/special/553"
                              >{{isCN? "病例大赛" : "Case"}}</a>
                            </li>
                            <li v-if="isCN">
                              <a
                                class="nav-link scrollto"
                                href="https://www.drvoice.cn/v2/sroom/1120"
                              >云展厅</a>
                            </li>-->
              <li class="dropdown">
                <a>
                  {{ isCN ? "回顾" : "Review" }}
                  <i class="bi bi-chevron-down"/>
                </a>
                <ul class="huigu">
                  <li><a @click="goPrevious(2024)">2024</a></li>
                  <li><a @click="goPrevious(2023)">2023</a></li>
                  <li><a @click="goPrevious(2022)">2022</a></li>
                  <li><a @click="goPrevious(2021)">2021</a></li>
                  <li><a @click="goPrevious(2020)">2020</a></li>
                  <li><a @click="goPrevious(2019)">2019</a></li>
                  <li><a @click="goPrevious(2018)">2018</a></li>
                  <li><a @click="goPrevious(2017)">2017</a></li>
                  <li><a @click="goPrevious(2016)">2016</a></li>
                </ul>
              </li>
              <li>
                <a
                    :href="isCN ? 'https://www.drvoice.cn/v2/conf/1504/home' : 'https://www.drvoice.cn/v2/conf/1504/home?language=en'"
                >{{ isCN ? "登录注册" : "Sign up" }}</a>
              </li>
            </ul>
             <div class="lang-check" @click="isCN = !isCN">
              {{ isCN ? "EN | 英文" : "CN | 中文" }}
            </div>
            <i @click="mobileNavToggle" class="bi bi-list mobile-nav-toggle"/>
          </nav>
        </div>
      </header>
      {{ isCN ? "" : "" }}
      <main class="content">
        <div class="place">
          <a @click="$router.push({name: 'home'})">{{ isCN ? "首页" : "Home" }}</a>
          <a>></a>
          <a>{{ isCN ? "征文投稿" : "Call for abstracts" }}</a>
        </div>
        <div>
          <div class="title">
            <div>{{ isCN ? "征文投稿" : "Call for abstracts" }}</div>
            <div class="bottom-sol">
              <div></div>
            </div>
          </div>
          <div class="child-content">
            <div class="header">
              <div>
                <strong>{{ isCN ? "征文通知" : "Paper Submission Notice" }}</strong>
              </div>
            </div>
            <div class="child-child-content">
              <div class="desc" style="display: none;">
                <div>
                  <span>2025年4月14日至20日，一年一度的CHINA VALVE（HANGZHOU）2025</span>
                  将在杭州如期举办。本次大会目前已开始论文征集及评选，诚邀您登陆官网：www.china-valve.org.cn 踊跃投稿。
                </div>
              </div>
              <div class="child-child-table">
                <div class="table-desc">
                  {{ isCN ? "本次大会征文内容包括：" : "Submission Categories" }}
                </div>
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">{{ isCN ? "序号" : "Number" }}</th>
                      <th scope="col">{{ isCN ? "论文方向" : "Direction of the paper" }}</th>
                      <th scope="col">{{ isCN ? "序号" : "Number" }}</th>
                      <th scope="col">{{ isCN ? "论文方向" : "Direction of the paper" }}</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>{{ isCN ? "结构性心脏病领域的临床研究" : "Clinical Research in Structural Heart Disease" }}</td>
                    <th scope="row">4</th>
                    <td>{{ isCN ? "结构性心脏病交叉学科研究" : "Interdisciplinary Research in Structural Heart Disease" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>{{ isCN ? "结构性心脏病领域基础研究" : "Basic Research in Structural Heart Disease" }}</td>
                    <th scope="row">5</th>
                    <td>{{ isCN ? "结构性心脏病领域麻醉相关研究" : "Anesthesia-Related Research in Structural Heart Disease" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>{{ isCN ? "结构性心脏病影像研究" : "Imaging Research in Structural Heart Disease" }}</td>
                    <th scope="row">6</th>
                    <td>{{ isCN ? "结构性心脏病领域护理相关研究" : "Nursing-Related Research in Structural Heart Disease" }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="submission-demand">
                <div>
                  {{ isCN ? "征文要求" : "Requirements" }}
                </div>
                <div>
                  {{ isCN ? "1、符合上述征文内容尚未公开发表的论文，以论文全文或论文摘要的形式投稿。" : "1. Submit your abstracts of original research." }}
                </div>
                <div>
                  {{ isCN ? "2、摘要应包括题目、作者、单位、目的、方法、结果及结论，字数500-800字，不含图表。" : "2. Abstract Content: The abstract should include the title, author(s), affiliation, purpose, methods, results, and conclusions. The word count should be between 500-800 words (excluding charts and graphs)." }}
                </div>
                <div>
                  <template v-if="isCN">3、所有征集的有效论文都会由专家评审组统一评审后，择优评选出优秀论文奖，邀请至大会现场进行汇报，<span style="color: #f00;">并推荐至JACC、JACC:Asia杂志</span>。</template>
                  <template v-else>3. All valid submissions will be reviewed by an expert panel. Outstanding papers will be invited to present at the conference and recommended for publication in <span style="color: #f00;">JACC or JACC: Asia</span>.</template>
                </div>
              </div>
              <div class="submission-way">
                <div>
                  {{ isCN ? "投稿方式" : "Submission method" }}
                </div>
                <div>
                  <template v-if="isCN">为了方便投稿、修改以及第一时间了解您稿件的接收和安排情况，会议只接收互联网在线投稿，不接收电子邮件或纸质投稿。请登录会议的专用网站（ <a href="http://www.china-valve.org.cn">http://www.china-valve.org.cn</a>），并点击导航栏中的“<span style="font-weight: bold">征文投稿</span>”，了解投稿要求、程序和细节。</template>
                  <template v-else>In order to facilitate submission, revision, and the first time to understand the receipt and arrangement of your manuscript, the conference only accepts online submission, not E-mail or paper submission. Please visit the conference web site (<a href="http://www.china-valve.org.cn">http://www.china-valve.org.cn</a>), Click "<span style="font-weight: bold">Submit</span>" in the navigation bar to understand the submission requirements, procedures and details.</template>
                </div>
                <div style="font-weight: bold" v-if="isCN">
                  开放时间：2025年1月01日
                </div>
               <div style="font-weight: bold" v-if="isCN">
                  截至时间：2025年2月16日
               </div>
              <div style="font-weight: bold" v-if="!isCN">
                Submit your abstract
              </div>
              <div style="font-weight: bold" v-if="!isCN">
                Submission ends: February 16, 2025
              </div>
              </div>
              <div class="submission-concat">
                <div style="font-weight: bold">
                  {{ isCN ? "投稿咨询：" : "Contact Information" }}
                  <div>
                    {{ isCN ? "基础研究：胡王兴，" : "Basic Research: Wangxing Hu, Tel: " }}+86 19521572835
                  </div>
                  <div>
                    {{ isCN ? "临床研究：范嘉祺，" : "Clinical Research: Jiaqi Fan, Tel: " }}+86 15267029492
                  </div>
                  <div>
                    {{ isCN ? "技术支持：杨  工，" : "Technical Support: Mr Yang, Tel: " }}+86 13237186198
                  </div>
                </div>
                <div style="font-weight: bold">
                  {{ isCN ? "邮箱：" : "Email: " }}cvh2025@china-valve.org.cn
                </div>
              </div>
              <div class="submission-submit">
                <button style="width: 200px" type="button" class="btn btn-primary btn-lg" @click="submitHandle">{{ isCN ? "点击投稿" : "Submit" }}</button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  <div v-else class="essay-submission-container-mobile">
    <div style="padding-top: 0.1px">
      <header id="header" class="d-flex align-items-center">
        <div class="container d-flex align-items-center justify-content-between">
          <div></div>
          <nav id="navbar" class="navbar">
             <div class="lang-check" @click="isCN = !isCN">
              {{ isCN ? "EN | 英文" : "CN | 中文" }}
            </div>
            <ul>
              <li>
                <a
                    class="nav-link scrollto"
                    @click="$router.push({name: 'home'})"
                    style="cursor: pointer"
                >{{ isCN ? "首页" : "Home" }}</a
                >
              </li>
              <!--            <li class="dropdown" @click="forumsDropdownHandle">
                            <a
                            >{{ isCN ? "日程" : "Program" }}
                              <i class="bi bi-chevron-down"></i
                              ></a>
                            <ul class="forums">
                              <template v-if="isCN">
                                <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864">会议日程</a></li>
                                <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864/search?agendaId=1394574515748864">讲者检索</a></li>
                              </template>
                              <template v-else>
                                <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864?en=1">Forums</a></li>
                                <li><a href="https://www.drvoice.cn/v2/programme/1394574515748864/search?agendaId=1394574515748864&en=1">Speakers</a></li>
                              </template>
                            </ul>
                          </li>-->
              <li>
                <a
                    class="nav-link scrollto active"
                    @click="$router.push({name: 'essaySubmission'})"
                    style="cursor: pointer"
                >{{ isCN ? "征文投稿" : "Call for abstracts" }}</a>
              </li>
              <li>
                <a
                    class="nav-link scrollto"
                    @click="$router.push({name: 'caseSubmission'})"
                    style="cursor: pointer"
                >{{ isCN ? "病例投稿" : "Call for cases" }}</a>
              </li>
              <li>
                <a
                    class="nav-link scrollto"
                    href="https://www.drvoice.cn/v2/course/3328"
                >{{ isCN ? "网络直播" : "Webcast" }}</a>
              </li>
              <li>
                <a
                    class="nav-link scrollto"
                    :href="isCN?'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320':'https://www.drvoice.cn/v2/conf/1120/column/1405621418328320?language=en'"
                >Webinars</a>
              </li>
              <li v-if="isCN">
                <a
                    class="nav-link scrollto"
                    href="https://www.pailixiang.com/m/album/main_ig65930788.html"
                >照片直播</a>
              </li>
              <li>
                <a
                  class="nav-link scrollto"
                  :href="isCN?'https://www.drvoice.cn/v2/conf/1504/column/1423367011369472':'https://www.drvoice.cn/v2/conf/1504/column/1423366932603392'"
                  target="_blank"
                >{{ isCN?'资料下载':'Download' }}</a>
              </li>
              <!--            <li v-if="isCN">
                            <a
                                class="nav-link scrollto"
                                href="https://www.drvoice.cn/cvh2024/workshop"
                            >Workshop预约</a>
                          </li>
                          <li v-if="!isCN">
                            <a
                                class="nav-link scrollto"
                                href="https://www.drvoice.cn/v2/conf/1120/column/1400736462077952?language=en"
                            >Download</a>
                          </li>
                          <li class="dropdown" v-if="isCN">
                          <li class="dropdown" @click="dropdownHandles">
                            <a href="#"
                            >会议资料
                              <i class="bi bi-chevron-down"></i
                              ></a>
                            <ul class="presenter">
                              <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1403064991039744">参会指南</a></li>
                              <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1404045771178240">会议资讯</a></li>
                              <li><a href="https://www.drvoice.cn/v2/conf/1120/column/1400736361062400">资料下载</a></li>
                            </ul>
                          </li>
                          <li v-if="!isCN">
                            <a
                                class="nav-link scrollto"
                                href="https://www.drvoice.cn/v2/conf/1120/column/1404045771178240"
                            >News</a>
                          </li>
                          <li >
                            <a
                                class="nav-link scrollto"
                                href="https://www.drvoice.cn/v2/special/553"
                            >{{isCN? "病例大赛" : "Case"}}</a>
                          </li>
                          <li v-if="isCN">
                            <a
                                class="nav-link scrollto"
                                href="https://www.drvoice.cn/v2/sroom/1120"
                            >云展厅</a>
                          </li>-->
              <li class="dropdown" @click="dropdownHandle">
                <a>
                  {{ isCN ? "回顾" : "Review" }}
                  <i class="bi bi-chevron-down"/>
                </a>
                <ul class="huigu">
                  <li><a @click="goPrevious(2024)">2024</a></li>
                  <li><a @click="goPrevious(2023)">2023</a></li>
                  <li><a @click="goPrevious(2022)">2022</a></li>
                  <li><a @click="goPrevious(2021)">2021</a></li>
                  <li><a @click="goPrevious(2020)">2020</a></li>
                  <li><a @click="goPrevious(2019)">2019</a></li>
                  <li><a @click="goPrevious(2018)">2018</a></li>
                  <li><a @click="goPrevious(2017)">2017</a></li>
                  <li><a @click="goPrevious(2016)">2016</a></li>
                </ul>
              </li>
              <li>
                <a
                    :href="
                       isCN
                          ? 'https://www.drvoice.cn/v2/conf/1504/home'
                          : 'https://www.drvoice.cn/v2/conf/1504/home?language=en'
                    "
                >{{ isCN ? "注册登录" : "Sign up" }}</a
                >
              </li>
            </ul>
            <i @click="mobileNavToggle" class="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>
      <main class="content">
        <div class="place">
          <a @click="$router.push({name: 'home'})">{{ isCN ? "首页" : "Home" }}</a>
          <a>></a>
          <a>{{ isCN ? "征文投稿" : "Call for abstracts" }}</a>
        </div>
        <div>
          <div class="title">
            <div>{{ isCN ? "征文投稿" : "Call for abstracts" }}</div>
            <div class="bottom-sol">
              <div></div>
            </div>
          </div>
          <div class="child-content">
            <div class="header">
              <div>
                <strong>{{ isCN ? "征文通知" : "Paper Submission Notice" }}</strong>
              </div>
            </div>
            <div class="child-child-content">
              <div class="desc" style="display: none;">
                <div>
                  <span>2025年4月14日至20日，一年一度的CHINA VALVE（HANGZHOU）2025</span>
                  将在杭州如期举办。本次大会目前已开始论文征集及评选，诚邀您登陆官网：www.china-valve.org.cn 踊跃投稿。
                </div>
              </div>
              <div class="child-child-table">
                <div class="table-desc">
                  {{ isCN ? "本次大会征文内容包括：" : "Submission Categories" }}
                </div>
                <table class="table table-striped table-bordered">
                  <thead>
                  <tr>
                    <th scope="col">{{ isCN ? "序号" : "Number" }}</th>
                    <th scope="col">{{ isCN ? "论文方向" : "Direction of the paper" }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>{{ isCN ? "结构性心脏病领域的临床研究" : "Clinical Research in Structural Heart Disease" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>{{ isCN ? "结构性心脏病领域基础研究" : "Basic Research in Structural Heart Disease" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>{{ isCN ? "结构性心脏病影像研究" : "Imaging Research in Structural Heart Disease" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>{{ isCN ? "结构性心脏病交叉学科研究" : "Interdisciplinary Research in Structural Heart Disease" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>{{ isCN ? "结构性心脏病领域麻醉相关研究" : "Anesthesia-Related Research in Structural Heart Disease" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>{{ isCN ? "结构性心脏病领域护理相关研究" : "Nursing-Related Research in Structural Heart Disease" }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="submission-demand">
                <div>
                  {{ isCN ? "征文要求" : "Requirements" }}
                </div>
                <div>
                  {{ isCN ? "1、符合上述征文内容尚未公开发表的论文，以论文全文或论文摘要的形式投稿。" : "1. Submit your abstracts of original research." }}
                </div>
                <div>
                  {{ isCN ? "2、摘要应包括题目、作者、单位、目的、方法、结果及结论，字数500-800字，不含图表。" : "2. Abstract Content: The abstract should include the title, author(s), affiliation, purpose, methods, results, and conclusions. The word count should be between 500-800 words (excluding charts and graphs)." }}
                </div>
                <div>
                  <template v-if="isCN">3、所有征集的有效论文都会由专家评审组统一评审后，择优评选出优秀论文奖，邀请至大会现场进行汇报，<span style="color: #f00;">并推荐至JACC、JACC:Asia杂志</span>。</template>
                  <template v-else>3. All valid submissions will be reviewed by an expert panel. Outstanding papers will be invited to present at the conference and recommended for publication in <span style="color: #f00;">JACC or JACC: Asia</span>.</template>
                </div>

              </div>
              <div class="submission-way">
                <div>
                  {{ isCN ? "投稿方式" : "Submission method" }}
                </div>
                <div>
                  <template v-if="isCN">为了方便投稿、修改以及第一时间了解您稿件的接收和安排情况，会议只接收互联网在线投稿，不接收电子邮件或纸质投稿。请登录会议的专用网站（ <a href="http://www.china-valve.org.cn">http://www.china-valve.org.cn</a>），并点击导航栏中的“<span style="font-weight: bold">征文投稿</span>”，了解投稿要求、程序和细节。</template>
                  <template v-else>In order to facilitate submission, revision, and the first time to understand the receipt and arrangement of your manuscript, the conference only accepts online submission, not E-mail or paper submission. Please visit the conference web site (<a href="http://www.china-valve.org.cn">http://www.china-valve.org.cn</a>), Click "<span style="font-weight: bold">Submit</span>" in the navigation bar to understand the submission requirements, procedures and details.</template>
                </div>
                <div style="font-weight: bold" v-if="isCN">
                  开放时间：2025年1月01日
                </div>
               <div style="font-weight: bold" v-if="isCN">
                  截至时间：2025年2月16日
                </div>
                <div style="font-weight: bold" v-if="!isCN">
                  Submit your abstract
                </div>
                <div style="font-weight: bold" v-if="!isCN">
                  Submission ends: February 16, 2025
                </div>
              </div>
              <div class="submission-concat">
                <div style="font-weight: bold">
                  {{ isCN ? "投稿咨询：" : "Contact Information" }}
                  <div>
                    {{ isCN ? "基础研究：胡王兴，" : "Basic Research: Wangxing Hu, Tel: " }}+86 19521572835
                  </div>
                  <div>
                    {{ isCN ? "临床研究：范嘉祺，" : "Clinical Research: Jiaqi Fan, Tel: " }}+86 15267029492
                  </div>
                  <div>
                    {{ isCN ? "技术支持：杨  工，" : "Technical Support: Mr Yang, Tel: " }}+86 13237186198
                  </div>
                </div>
                <div style="font-weight: bold">
                  {{ isCN ? "邮箱：" : "e-mail:" }}cvh2025@china-valve.org.cn
                </div>
              </div>
              <div class="submission-submit">
                <button style="width: 200px" type="button" class="btn btn-primary" @click="submitHandle">{{ isCN ? "点击投稿" : "Submit" }}</button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {isMobile, onscroll, selectEl} from "../utils/util";
import "../assets/css/bootstrap-utilities.css";
import {initWeChatForwardConfiguration} from "../utils/weChatForward";

  export default {
    name: "EssaySubmission",
    data() {
      return {
        isCN: false,
        isMobile: isMobile()
      }
    },
    created() {
      initWeChatForwardConfiguration("CHINA VALVE（HANGZHOU）2025", window.location.href, "", "2025年4月14-20日 中国·杭州")
      this.isCN = navigator.language === "zh-CN";
    },
    methods: {
      init() {
        this.headerFixed();
        // this.backToTopHandle();
        // this.skilsContentHandle();
        // if (!this.isMobile){
        //   this.initSwiper();
        // }
        // this.initAos();
      },
      goPrevious(year) {
        let url = "http://cvh" + year + ".china-valve.org.cn/";
        if (!this.isCN) {
          url += "?language=en";
        }
        window.open(url);
      },
      mobileNavToggle() {
        selectEl("#navbar").classList.toggle("navbar-mobile");
        selectEl(".mobile-nav-toggle").classList.toggle("bi-list");
        selectEl(".mobile-nav-toggle").classList.toggle("bi-x");
      },
      headerFixed() {
        let selectHeader = selectEl("#header");
        if (!selectHeader) {
          setTimeout(() => {
            this.headerFixed();
          }, 100);
          return;
        }
        if (selectHeader) {
          // let headerOffset = selectHeader.offsetTop
          let nextElement = selectHeader.nextElementSibling;
          let headerFixed = null;
          if (this.isMobile) {
            headerFixed = () => {
              selectHeader.classList.add("fixed-top");
              nextElement.classList.add("scrolled-offset");
              // selectHeader.classList.remove('fixed-top')
              // nextElement.classList.remove('scrolled-offset')
            };
          } else {
            headerFixed = () => {
              if (window.scrollY >= 16) {
                selectHeader.classList.add("fixed-top");
                nextElement.classList.add("scrolled-offset");
              } else {
                selectHeader.classList.remove("fixed-top");
                nextElement.classList.remove("scrolled-offset");
              }
            };
          }
          window.addEventListener("load", headerFixed);
          onscroll(document, headerFixed);
        }
      },
      dropdownHandle() {
        selectEl(".huigu").classList.toggle("dropdown-active");
      },
      submitHandle() {
        const url = this.isCN?'https://www.drvoice.cn/v2/conf/1504/abstract?type=1':'https://www.drvoice.cn/v2/conf/1504/abstract?type=1&language=en'
        window.open(url);
      }
    }
  }
</script>

<style lang="less" scoped>
.essay-submission-container-pc {
  .content {
    width: 1100px;
    margin: 0 auto;
    .place {
      padding: 20px 0;
      color: #333;
      font-size: 16px;
      border-bottom: 1px solid #dfdfdf;
      a {
        display: inline-block;
        margin: 0 10px;
        color: #333;
      }
    }
    .title {
      text-align: center;
      font-size: 24px;
      margin-top: 30px;
      font-weight: bold;
      .bottom-sol {
        padding-top: 15px;
        height: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          width: 96px;
          height: 1px;
          color: #106eea;
          border-bottom: solid 2px #106eea;
        }
      }
    }
    .child-content {
      margin-top: 30px;
      .header {
        display: inline-block;
        width: auto;
        vertical-align: top;
        border-style: none solid;
        border-width: 9px;
        border-color: rgba(16, 110, 234, 0.05) transparent rgba(16, 110, 234, 0.05) #106eea;
        background-color: rgba(16, 110, 234, 0.05);
        color: #106eea;
        min-width: 10%;
        max-width: 100%;
        height: auto;
        box-sizing: border-box;
        div {
          line-height: 54px;
          padding: 0 20px;
          margin-right: 10px;
        }
      }
      .child-child-content {
        width: 100%;
        vertical-align: top;
        border-left: 1px solid #106eea;
        border-bottom-left-radius: 0;
        font-size: 14px;
        color: #5E5C5C;
        padding-right: 18px;
        padding-left: 18px;
        line-height: 2;
        letter-spacing: 0;
        .desc {
          padding-top: 15px;
          span {
            font-weight: bold;
            color: #106eea;
          }
        }
        .child-child-table {
          padding-top: 10px;
          .table-desc {
            font-weight: bold;
          }
          table {
            text-align: center;
            thead {
              tr {
                background-color: rgba(16, 110, 234, 0.05);
                th {
                  background-color: rgba(16, 110, 234, 0.05);
                }
              }
            }
          }
        }
        .submission-demand {
          & > div:first-child {
            font-weight: bold;
          }
        }
        .submission-way {
          padding-top: 10px;
          & > div:first-child {
            font-weight: bold;
          }
        }
        .submission-concat {
          padding-top: 20px;
        }
        .ppt-btn {
          padding-top: 20px;
          a {
            display: inline-block;
            margin-right: 20px;
            background-color: #0b5ed7;
            color: #fff;
            border-radius: 4px;
            padding: 0 20px;
          }
        }
        .submission-submit {
          text-align: center;
          padding: 50px 0 30px 0;
        }
      }
    }
  }
}
.essay-submission-container-mobile {
  .scrolled-offset {
    margin-top: 50px;
  }
  #header {
    height: 50px;
    /* background-color: #1015a3; */
    background-image: linear-gradient(to right, #0545b5, #000c60);
    background-color: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #header .navbar .mobile-nav-toggle {
    color: #fff;
    /* color: #193db3; */
  }
  .lang-check {
    color: #193db3;
    background-color: #fff;
    /* border: 1px solid #193db3; */
    border-radius: 4px;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    width: 78px;
    margin-right: 20px;
    margin-left: 80px;
    font-weight: 700;
  }

  .banner h1 {
    position: relative;
    z-index: 1;
    margin: 0;
    font-size: 26px;
    font-weight: 700;
    color: #222222;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
    background: linear-gradient(
        0deg,
        #ffebb9 0%,
        #ffd867 65.9912109375%,
        #ffeaa7 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .banner .yy {
    width: 100%;
    position: absolute;
    top: 36px;
    left: 0;
    color: transparent;
    font-size: 26px;
    font-weight: 700;
    text-shadow: -3px 5px 5px rgba(0, 0, 0, 0.3), -2px -3px 0 #000;
  }

  .banner h2 {
    color: #555555;
    margin: 20px 0 5px 0;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
  }
  .banner .logo img {
    width: 100px;
  }
  #hero .box {
    /* border-top: 1px solid #2c48c8;
      border-bottom: 1px solid #2c48c8; */
  }
  #hero .img-list {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }

  #hero .img-list .img-box {
    border: 1px solid #2c48c8;
    margin: 0 5px;
    list-style: none;
    white-space: nowrap;
    width: 45vw;
    padding: 2vw;
  }
  #hero .img-list img {
    width: 41vw;
  }
  .content {
    width: 100%;
    padding: 0 10px;
    .place {
      padding: 20px 0;
      color: #333;
      font-size: 16px;
      border-bottom: 1px solid #dfdfdf;
      a {
        display: inline-block;
        margin: 0 10px;
        color: #333;
      }
    }
    .title {
      text-align: center;
      font-size: 24px;
      margin-top: 30px;
      font-weight: bold;
      .bottom-sol {
        padding-top: 15px;
        height: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          width: 96px;
          height: 1px;
          color: #106eea;
          border-bottom: solid 2px #106eea;
        }
      }
    }
    .child-content {
      margin-top: 30px;
      .header {
        display: inline-block;
        width: auto;
        vertical-align: top;
        border-style: none solid;
        border-width: 9px;
        border-color: rgba(16, 110, 234, 0.05) transparent rgba(16, 110, 234, 0.05) #106eea;
        background-color: rgba(16, 110, 234, 0.05);
        color: #106eea;
        min-width: 10%;
        max-width: 100%;
        height: auto;
        box-sizing: border-box;
        div {
          line-height: 54px;
          padding: 0 20px;
          margin-right: 10px;
        }
      }
      .child-child-content {
        width: 100%;
        vertical-align: top;
        border-left: 1px solid #106eea;
        border-bottom-left-radius: 0;
        font-size: 14px;
        color: #5E5C5C;
        padding-right: 18px;
        padding-left: 18px;
        line-height: 2;
        letter-spacing: 0;
        .desc {
          padding-top: 15px;
          span {
            font-weight: bold;
            color: #106eea;
          }
        }
        .child-child-table {
          padding-top: 10px;
          .table-desc {
            font-weight: bold;
          }
          table {
            text-align: center;
            thead {
              tr {
                background-color: rgba(16, 110, 234, 0.05);
                th {
                  background-color: rgba(16, 110, 234, 0.05);
                }
              }
            }
          }
        }
        .submission-demand {
          & > div:first-child {
            font-weight: bold;
          }
        }
        .submission-way {
          padding-top: 10px;
          & > div:first-child {
            font-weight: bold;
          }
        }
        .submission-concat {
          padding-top: 20px;
        }
        .ppt-btn {
          padding-top: 20px;
          a {
            display: inline-block;
            margin-right: 20px;
            background-color: #0b5ed7;
            color: #fff;
            border-radius: 4px;
            padding: 0 20px;
          }
        }
        .submission-submit {
          text-align: center;
          padding: 50px 0 30px 0;
        }
      }
    }
  }
}
</style>
